<template>
  <div class="news-container">
    <div class="scroll-container">
         <div class="main-container">
            <div class="left">
                <div class="title-wrapper">
                <h2 class="title ellipsisTwo" :title="title">{{detailData && detailData.title}}</h2>
                <p class="t-c">
                    <!-- <span class="save">收藏</span> -->
                    <span :class="['save',{'active': isClocActive}]" @click="changeFollow">{{ clocText }}</span>
                    <span :class="['like',{'active': isLike}]" @click="changeLike" >{{ isLike ? '已点赞' : '点赞'}} {{likesNum}}</span>
                    <span class="read">阅读 {{detailData && detailData.readNum}}</span>
                    <span class="time">{{detailData && detailData.releaseTime}}</span>
                    <!-- <span class="tag">{{detailData && detailData.realmName}}</span>
                    <span class="tag">{{detailData && detailData.productName}}</span> -->
                    <span v-show="detailData && detailData.realmName" class="tag">{{detailData && detailData.realmName}}</span>
                    <span v-show="detailData && detailData.productName" class="tag">{{detailData && detailData.productName}}</span>
                    
                    
                </p>
                <button class="back" @click="goBack"></button>
                </div>
                <div class="content-wrapper">
                    <p>{{ detailData && detailData.info }}</p>
                    <!-- <h3 class="section-title">
                        <span class="num">-</span>
                        力挺！中外新指南一致推荐二甲双胍用于稳定的慢性心衰患者
                    </h3> -->
                    <!-- <p>{{ detailData && detailData.content }}</p> -->
                    <div v-html="detailData && detailData.content">{{ detailData && detailData.content }}</div>
                    <!-- <img style="width: 100%;" src="./images/n-1.png" alt=""> -->
                    <p class="audit-no" v-if="detailData.auditNo">{{detailData.auditNo}}</p>
                </div>
            </div>
            <div class="right">
                <recommended-reading></recommended-reading>
            </div>
         </div>
    </div>
  </div>
</template>

<script>
import RecommendedReading from "./recommended-reading";
import indexApi from '@/api/index'
export default {
  name: "news-details",
  components: {RecommendedReading},
  data() {
    return {
      title: '',
      tagList: [],
      time: '',
      newId: '',
      detailData:{},

      isLike:false,
      likesNum : 0,

      clocText:'收藏',
      isClocActive:false,
    }
  },
  created() {
    this.newId = this.$route.params.id;
    this.IgnoreSecurity = this.$route.query.IgnoreSecurity;
    if( this.newId ){
        this.getDynamicNewsDetail();
    }else{
        this.$router.replace({name: 'login'})
    }
  },
  mounted() {
    this.getListData();
  },
  methods: {
    getListData() {
      this.title = '心衰患者降糖有顾忌？指南喊你放心用二甲双胍啦！';
      this.time = '2020-11-20';
      this.tagList = [
        {
          id: 1,
          name: '糖尿病领域'
        },
        {
          id: 2,
          name: '格华止',
        }
      ]
    },

    // 获取详情信息
    getDynamicNewsDetail() {
      // getDynamicNewsDetail
      let params = {
        // phone: this.form.phone
        id: this.newId
      };
      if(this.IgnoreSecurity){
          params.IgnoreSecurity = this.IgnoreSecurity;
      }
      let url = '/perspective/detail/' + this.newId;
      indexApi.getDynamicNewsDetail(params,url).then((res)=>{
        if(res && res.code === 1000){
          let data = res && res.data
          this.detailData = data;

          this.isClocActive =  data.followStatus == 1 ? false : true;
          this.clocText = data.followStatus == 1 ? '收藏' : '取消';

          this.isLike = data.likesStatus  == 1 ? false : true;
          this.likesNum = data.likesNum;
		  if (this.IgnoreSecurity) return;
					this.$tools.readyLoadDetail(this);
        } else {
        	console.log(res);
        	let _message = res.message || '当前内容已过期，请您查看其它内容。';
        	this.$message.error(_message);
        }
      },(err)=>{
        this.$message.success(err && err.message || '获取失败，请联系管理员！')
      })
    },

    // 点击收藏-取消
    changeFollow() {
      let params = {
        "dataId": this.detailData && this.detailData.id,//数据ID 
        "type": "4",//类型 1产品 2活动 3资讯 4 临床视角
      };
      indexApi.followOrCanFollow(params).then((res)=>{
        this.isClocActive = !this.isClocActive;
        this.clocText = this.isClocActive ?  '取消' : '收藏';
      },(err)=>{
        this.$message.success(err && err.message || '操作失败，请联系管理员！')
    })
    },

    // 点赞-取消
    changeLike() {
      let params = {
        "dataId": this.detailData.id,//数据ID 
        "type": 4,//类型 1产品 2活动 3资讯 4 临床视角
      };
      indexApi.setUserLikes(params).then((res)=>{
          this.isLike = !this.isLike;
          if(this.isLike){
              this.likesNum = this.likesNum += 1;
          }else{
              this.likesNum = this.likesNum -= 1;
          }
      },(err)=>{
        this.$message.error(err && err.message || '操作失败，请联系管理员！')
      })
    },
    goBack() {
      this.$router.push({path: '/medical-records'})
    },
  }
}
</script>

<style scoped lang="scss">
.news-container {
  background-image: url("~./images/bg.png");
  background-size: cover;
  line-height: 1;
}


.scroll-container {
      width:100%;
    max-width: 100%;
   
  }
  .main-container {
       display: flex;
       align-items: flex-start;
  }
.left {
  flex: auto;
  border-radius: .22rem;
  margin-top: .6rem;
  position: relative;
  .title-wrapper {
    background: url("~./images/details-title.png") right top / 3rem auto no-repeat #503291;
    color: #fff;
    margin-bottom: -0.2rem;
    border-top-right-radius: .2rem;
    border-top-left-radius: .2rem;
    padding: .5rem;
    position: relative;
    .title {
      margin-bottom: .2rem;
      font-size: .3rem;
      max-width: 100%;
      padding-right: .8rem;
      box-sizing: border-box;
      line-height: .35rem;
    }
    .t-c {
      font-size: .14rem;
      .save{
        padding-left: .26rem;
        margin-right: .2rem;
        background: url("~./images/star.png") left center /.16rem .16rem no-repeat;
        cursor: pointer;
        &.active{
          background: url("~./images/star-active.png") left center /.16rem .16rem no-repeat;
        }
      }
      .time {
        padding-left: .26rem;
        margin-right: .2rem;
        background: url("~./images/time.png") no-repeat left /.16rem .16rem;
      }
      .like {
        cursor: pointer;
        padding-left: .26rem;
        margin-right: .2rem;
        background: url("../product/images/z-normal.png") no-repeat left center/.16rem .16rem;
        &.active{
          background: url("../product/images/z-click.png") left center /.16rem .16rem no-repeat;
        }
      }
      .read {
        padding-left: .26rem;
        margin-right: .2rem;
        background: url("../product/images/yd.png") no-repeat left center/.16rem .12rem;
      }
      .tag {
        background: rgba(255,255,255, .1);
        height: .22rem;
        font-size: .14rem;
        padding: 0 .1rem;
        line-height: .22rem;
        margin-right: .1rem;
        border-radius: 2px;
      }
    }
    .back {
      width: .52rem;
      height: .52rem;
      background: url("~./images/back.png") center center / .22rem .20rem no-repeat #eb3c96;
      position: absolute;
      right: .5rem;
      top: .4rem;
      border-radius: 100%;
      cursor: pointer;
    }
  }
  .content-wrapper {
    background: #fff;
    border-radius: .2rem;
    position: relative;
    z-index: 2;
    padding: .2rem .5rem;
    line-height: .34rem;
    color: #333;
    font-size: .16rem;
    overflow-x: hidden;
    .section-title {
      display: inline-block;
      border-radius: 2px;
      border: solid 1px #2dbecd;
      padding-right: .2rem;
      color: #2dbecd;
      margin: .2rem 0;
      .num {
        width: .42rem;
        text-align: center;
        display: inline-block;
        color: #fff;
        background: url("~./images/title.png") center center / 100% 100% no-repeat;
      }
    }
    .audit-no{
        color:#666;
        padding-top:0.1rem;
    }
  }

}
.right {
  flex: none;
  margin-top: .6rem;
  width: 4.5rem;
  margin-left: .1rem;
  position: sticky;
  top: 0;
}

</style>
